import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'

const Faq = () => {
  return (
    <>
      <Seo pageTitle={'FAQ'} description={`Frequently Asked Questions`} />
      <Layout>
        <main>
          <Article>
            <h1 className='display-4'>FAQ</h1>

            <div className='accordion' id='faqAccordion'>
              {/* Item One */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingOne'>
                  <button
                    className='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseOne'
                    aria-expanded='true'
                    aria-controls='collapseOne'
                  >
                    How important is Home Insurance?
                  </button>
                </h2>
                <div
                  id='collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='headingOne'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      Buildings insurance is compulsory if you have a mortgage.
                      Your mortgage company will insist that you have the
                      building insured and will be very keen to offer you cover
                      at a cost! You are not obliged to buy your insurance
                      through them and you may get a better deal by shopping
                      around. If you do not have a mortgage then Buildings
                      insurance is not compulsory. However, if you own the
                      property, buildings insurance is important as your home is
                      probably your biggest investment and should anything major
                      happen to it, how would you pay to put things right and
                      where would you live in the meantime? Contents insurance
                      is not compulsory. However, if you got home from work and
                      there had been a fire, how would you replace everything
                      that you have worked so hard to put into your home? You
                      would have no clothes to wear, no TV to watch and no sofa
                      to sit on. Contents insurance will cover you should the
                      worst happen.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Two */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingTwo'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseTwo'
                    aria-expanded='false'
                    aria-controls='collapseTwo'
                  >
                    Aren't price comparison sites the best place to get
                    insurance?
                  </button>
                </h2>
                <div
                  id='collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingTwo'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      It is a widely held belief that price comparison sites are
                      the cheapest way to buy insurance. However, what are you
                      getting for your money? How do you know that your policy
                      will cover you for everything that you want it to?
                      Unfortunately, it may take a claim to find out whether you
                      have the correct cover, and often this is too late as the
                      damage has already happened. At Legacy Home Protect Ltd,
                      we will discuss your insurance needs with you to ensure
                      that you know what you are, and are not, covered for and
                      are happy that in the event of damage to your home or
                      contents, you will be covered. Remember, the cheapest
                      insurance quotation can become an expensive nightmare if
                      you do not have the correct cover.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Three */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingThree'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseThree'
                    aria-expanded='false'
                    aria-controls='collapseThree'
                  >
                    Do you offer Accidental Damage Cover?
                  </button>
                </h2>
                <div
                  id='collapseThree'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingThree'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      Yes we do. All of our policies except for the Home
                      Emergency policy offers elements of Accidental Damage as
                      standard. If you are clumsy, or have kids, this cover
                      should be considered. Buildings policies will
                      automatically offer cover for Accidental Damage to your
                      bathroom suite, underground pipes and cables and glass in
                      windows and doors. Should you want additional cover, at an
                      extra charge all accidents, such as putting your foot
                      through the ceiling, DIY accidents and damage caused by
                      taps being left on can be included. Contents policies will
                      automatically offer cover for Accidental Damage for items
                      such as TV's, DVD players, hi-fi's, home office equipment
                      and fixed glass in furniture. Damage to ceramic hobs is
                      also covered. Should you want additional cover, at an
                      extra charge cover for accidents such as spilling things
                      in your carpet or sofa, or damage to ornaments and other
                      electrical items can be included. Our trained Home
                      Insurance staff will be able to discuss your requirements
                      with you to provide you with the cover you want.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Four */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingFour'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseFour'
                    aria-expanded='false'
                    aria-controls='collapseFour'
                  >
                    Am I best to have two separate policies, one for Buildings &
                    one for Contents?
                  </button>
                </h2>
                <div
                  id='collapseFour'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingFour'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      Having Buildings and Contents insurance on the same policy
                      is usually the best way of insuring your home. Buying them
                      both on the same policy will often save you money. Having
                      Buildings and Contents together with us also means that in
                      the event of a claim that covers both sections you will
                      only have to contact one insurance company and pay one
                      excess. If you have them with two different companies then
                      you have to call two insurance companies and pay two
                      excesses. This will also usually increase the time taken
                      to deal with your claim.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Five */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingFive'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseFive'
                    aria-expanded='false'
                    aria-controls='collapseFive'
                  >
                    How soon can my cover start?
                  </button>
                </h2>
                <div
                  id='collapseFive'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingFive'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      We can start your cover immediately. However, you cannot
                      claim for any loss or damage that has already happened.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Six */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingSix'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseSix'
                    aria-expanded='false'
                    aria-controls='collapseSix'
                  >
                    Can I pay my home insurance by instalments?
                  </button>
                </h2>
                <div
                  id='collapseSix'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingSix'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      You can choose to pay your premium monthly by direct debit
                      or annually by credit card, debit card or direct debit.
                      There is no extra charge to pay by direct debit.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Seven */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingSeven'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseSeven'
                    aria-expanded='false'
                    aria-controls='collapseSeven'
                  >
                    Are my personal items covered when I leave the home?
                  </button>
                </h2>
                <div
                  id='collapseSeven'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingSeven'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      By including the optional "Personal Possessions" section,
                      you will then be covered if you lose an item within your
                      property and also away from your home. This means that
                      your belongings will also be covered when you go away on
                      holiday (as long as you do not travel outside of the EU
                      for more than 60 days in a year.)
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Eight */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingEight'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseEight'
                    aria-expanded='false'
                    aria-controls='collapseEight'
                  >
                    Will my home insurance cover the contents of my garage/shed?
                  </button>
                </h2>
                <div
                  id='collapseEight'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingEight'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      Under our Contents insurance you will be covered for items
                      stolen from a garage, shed or other outbuilding subject to
                      policy limits. Should items in any outbuilding be damaged
                      by any other insured event then you will be covered for up
                      to the full contents sum insured.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Nine */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingNine'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseNine'
                    aria-expanded='false'
                    aria-controls='collapseNine'
                  >
                    Can I get insurance if I let my property to someone else?
                  </button>
                </h2>
                <div
                  id='collapseNine'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingNine'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      Yes. We also offer Buy-to-Let policies for people who let
                      their properties to others. These policies will cover the
                      Buildings of the property, and can also be extended to
                      offer Contents cover should you leave any items in your
                      property.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Ten */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingTen'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseTen'
                    aria-expanded='false'
                    aria-controls='collapseTen'
                  >
                    Will I be able to get insurance if I rent my house/flat?
                  </button>
                </h2>
                <div
                  id='collapseTen'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingTen'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      Yes you can. We offer Contents only policies to people who
                      rent their property. Whether it is rented from the council
                      or a private landlord we can offer a policy to suit your
                      needs. Our policies even cover certain damage that you may
                      cause to the Landlord's property.
                    </p>
                  </div>
                </div>
              </div>

              {/* Item Eleven */}
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingEleven'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseEleven'
                    aria-expanded='false'
                    aria-controls='collapseEleven'
                  >
                    Is cover provided for things in my garden?
                  </button>
                </h2>
                <div
                  id='collapseEleven'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingEleven'
                  data-bs-parent='faqAccordion'
                >
                  <div className='accordion-body'>
                    <p>
                      We can start your cover immediately. However, you cannot
                      claim for any loss or damage that has already
                      happened.Under our Contents insurance, items such as
                      barbecues, garden furniture and other contents will be
                      covered in the garden up to £750 for certain damage.
                      Plants in pots and containers, and the pots and containers
                      themselves, are also covered up to £750.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Article>
        </main>
      </Layout>
    </>
  )
}

export default Faq
